<ng-container *transloco="let t; prefix: 'coreUiComponents.pages.warehouses'">
  <h2 class="content-block">{{ t('title') }}</h2>
  @if (facade.state$ | async; as state) {
    @if (state.loading) {
      <div class="loading-container">
        <dx-load-indicator height="60" width="60" />
      </div>
    } @else {
      <ul class="content-block warehouses-container">
        @for (warehouse of state.warehouses; track warehouse.id) {
          <li class="warehouse shadow">
            <div class="warehouse-title p-3">
              <h3>{{ warehouse.name }}</h3>
            </div>
            <address class="m-3">
              {{ warehouse.street }}<br />
              {{ warehouse.zipCode }} {{ warehouse.city }}<br />
              {{ warehouse.country }}
            </address>
            <time class="d-block m-3">
              <h5><i class="fa-regular fa-clock"></i> {{ t('businessHours') }}</h5>
              <ul>
                @for (hours of warehouse.businessHours; track hours.day) {
                  <li>
                    <span>{{ t(hours.day) }}</span
                    ><br />
                    @if (hours.morning == undefined && hours.afternoon == undefined) {
                      <small>{{ t('closed') }}</small>
                    } @else {
                      <small [innerHTML]="t('time', hours)"></small>
                    }
                  </li>
                } @empty {
                  <li>{{ t('noBusinessHour') }}</li>
                }
              </ul>
            </time>
          </li>
        } @empty {
          <li class="warehouse shadow-sm">{{ t('noWarehouse') }}</li>
        }
      </ul>
    }
  }
</ng-container>
