import { Injectable } from '@angular/core';
import { injectStore } from '@webshop/shared';

import { coreFeature } from '../+state/core.feature';
import { OrderTypeActions } from '../+state/order-type/order-type.actions';
import { SelectOrderTypeRequest } from '../dtos/select-order-type-request';

@Injectable({ providedIn: 'root' })
export class AppFacade {
  private readonly store = injectStore();
  readonly state$ = this.store.select(coreFeature.selectForApp);

  loadCurrentOrderType(): void {
    this.store.dispatch(OrderTypeActions.loadCurrent());
  }

  showOrderTypeSelectionDialog(): void {
    this.store.dispatch(OrderTypeActions.showSelectionDialog());
  }

  closeOrderTypeSelectionDialog(): void {
    this.store.dispatch(OrderTypeActions.closeSelectionDialog());
  }

  selectOrderType(orderType: SelectOrderTypeRequest): void {
    this.store.dispatch(OrderTypeActions.selectOrderType({ orderType }));
  }

  initOrderTypeSettings(): void {
    this.store.dispatch(OrderTypeActions.initSettings());
  }

  hideOrderTypeBanner(): void {
    this.store.dispatch(OrderTypeActions.setSettings({ settings: { showBanner: false } }));
  }
}
