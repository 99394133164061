import { ChangeDetectionStrategy, Component, computed, inject, input, output } from '@angular/core';
import { isNeitherNullNorUndefined } from '@equans/core';
import { provideTranslocoScope, TranslocoDirective } from '@jsverse/transloco';
import { FullOrderType, SelectOrderTypeRequest, SimpleOrderType } from '@webshop/core/domain';
import { DisableToolbarButtonPipe } from '@webshop/shared';
import { DxAccordionModule, DxLoadIndicatorModule, DxPopupModule, DxRadioGroupModule } from 'devextreme-angular';

import { OrderTypeSelectionDialogStore, FULL_CATALOG_ID } from './order-type-selection-dialog.store';

@Component({
  selector: 'core-order-type-selection-dialog',
  standalone: true,
  imports: [DxPopupModule, TranslocoDirective, DxLoadIndicatorModule, DxAccordionModule, DxRadioGroupModule, DisableToolbarButtonPipe],
  templateUrl: './order-type-selection-dialog.component.html',
  styleUrl: './order-type-selection-dialog.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [provideTranslocoScope({ scope: 'core/ui-components' }), OrderTypeSelectionDialogStore],
})
export class OrderTypeSelectionDialogComponent {
  private readonly store = inject(OrderTypeSelectionDialogStore);
  readonly visible = input.required<boolean>();
  readonly availableOrderTypes = input.required<FullOrderType[]>();
  readonly loading = input.required<boolean>();
  readonly currentOrderType = input.required<SimpleOrderType>();
  readonly selectOrderType = output<SelectOrderTypeRequest>();
  readonly cancel = output();
  // eslint-disable-next-line @typescript-eslint/naming-convention
  readonly FULL_CATALOG_ID = FULL_CATALOG_ID;
  readonly selectedOrderTypeId = this.store.selectedOrderTypeId;
  readonly selectedWarehouseId = this.store.selectedWarehouseId;
  readonly orderTypes = this.store.orderTypes;
  readonly disableOkButton = this.store.disableOkButton;

  constructor() {
    this.onSelectOrderType = this.onSelectOrderType.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.store.init(
      computed(() => ({
        availableOrderTypes: this.availableOrderTypes(),
        currentOrderType: this.currentOrderType(),
      }))
    );
  }

  onSelectOrderType(): void {
    const selectedOrderTypeAndWarehouse = this.store.selectedOrderTypeAndWarehouse();
    if (isNeitherNullNorUndefined(selectedOrderTypeAndWarehouse)) {
      this.selectOrderType.emit(selectedOrderTypeAndWarehouse);
    }
  }

  onCancel(): void {
    this.cancel.emit();
  }

  setSelectedOrderTypeId(orderTypeId: number): void {
    this.store.setSelectedOrderTypeId(orderTypeId);
  }

  setSelectedWarehouseId(warehouseId: string): void {
    this.store.setSelectedWarehouseId(warehouseId);
  }
}
