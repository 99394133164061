import { provideHttpClient, withInterceptors, withInterceptorsFromDi } from '@angular/common/http';
import { ApplicationConfig, ENVIRONMENT_INITIALIZER, inject } from '@angular/core';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import {
  provideRouter,
  withComponentInputBinding,
  withDisabledInitialNavigation,
  withEnabledBlockingInitialNavigation,
} from '@angular/router';
import { BrowserUtils } from '@azure/msal-browser';
import { provideCommon, retryInterceptor } from '@equans/common';
import { provideUI, withApplicationVersionChecking, withGlobalErrorHandler } from '@equans/components';
import { provideCore } from '@equans/core';
import { provideSecurity } from '@equans/security';
import { TranslocoService } from '@jsverse/transloco';
import { provideEffects } from '@ngrx/effects';
import { NavigationActionTiming, provideRouterStore, routerReducer, RouterState } from '@ngrx/router-store';
import { provideStore } from '@ngrx/store';
import { provideStoreDevtools } from '@ngrx/store-devtools';
import { AccessDeniedComponent, provideCoreDomain } from '@webshop/core/api';
import { ACCESS_DENIED_ROUTE, accessDeniedInterceptor, handleGenericError$, handleHttpError$, handleZodError$ } from '@webshop/shared';
import { take } from 'rxjs';

import { generateAppMenu as appMenu } from './app.menu';
import { APP_ROUTES } from './app.routes';
import { APP_VERSION } from './app.version';

export const APP_CONFIG: ApplicationConfig = {
  providers: [
    provideAnimationsAsync(),
    provideRouter(
      APP_ROUTES,
      !BrowserUtils.isInIframe() && !BrowserUtils.isInPopup() ? withEnabledBlockingInitialNavigation() : withDisabledInitialNavigation(),
      withComponentInputBinding()
    ),
    provideHttpClient(withInterceptorsFromDi(), withInterceptors([accessDeniedInterceptor(), retryInterceptor({ maxRetries: 3 })])),
    provideCore({ versionInfo: APP_VERSION }),
    provideCommon({ availableLanguages: ['en', 'fr', 'nl'], defaultLanguage: 'en' }),
    provideSecurity({
      forbiddenPath: ACCESS_DENIED_ROUTE,
      forbiddenComponent: AccessDeniedComponent,
    }),
    provideUI({ appMenu }, withApplicationVersionChecking({ frequency: 60_000, appName: 'app.title' }), withGlobalErrorHandler()),
    provideStore(
      { router: routerReducer },
      {
        runtimeChecks: {
          strictActionImmutability: true,
          strictActionSerializability: true,
          strictActionTypeUniqueness: true,
          strictActionWithinNgZone: true,
          strictStateSerializability: true,
          strictStateImmutability: true,
        },
      }
    ),
    provideRouterStore({ routerState: RouterState.Minimal, navigationActionTiming: NavigationActionTiming.PostActivation }),
    provideEffects({ handleZodError$, handleHttpError$, handleGenericError$ }),
    provideStoreDevtools(),
    {
      provide: ENVIRONMENT_INITIALIZER,
      multi: true,
      useValue: () => {
        // force the loading of the app's translations
        const translocoService = inject(TranslocoService);
        translocoService.selectTranslate('app.title').pipe(take(1)).subscribe();
      },
    },
    provideCoreDomain(),
  ],
};
