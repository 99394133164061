import { inject } from '@angular/core';
import { isNullOrUndefined } from '@equans/core';
import { createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { handleError, injectActions, injectStore } from '@webshop/shared';
import { map, of, switchMap } from 'rxjs';

import { WarehouseService } from '../../infrastructure/warehouse.service';
import { coreFeature } from '../core.feature';

import { WarehouseActions } from './warehouse.actions';

export const loadAllWarehouses$ = createEffect(
  (actions$ = injectActions(), store = injectStore(), warehousesService = inject(WarehouseService)) => {
    return actions$.pipe(
      ofType(WarehouseActions.loadAll),
      concatLatestFrom(() => store.select(coreFeature.selectWarehouses)),
      switchMap(([{ refresh }, loadedWarehouses]) =>
        isNullOrUndefined(loadedWarehouses) || refresh
          ? warehousesService.loadAllWarehouses().pipe(
              map((warehouses) => WarehouseActions.loadAllSucceeded({ warehouses })),
              handleError('coreDomain.messages.loadAllWarehousesFailed', WarehouseActions.loadAllFailed())
            )
          : of(WarehouseActions.alreadyAllLoaded())
      )
    );
  },
  { functional: true }
);
