import { AsyncPipe } from '@angular/common';
import { Component, inject, OnInit, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { RouterOutlet } from '@angular/router';
import { LayoutComponent, LayoutDirective, LayoutFooterComponent, LayoutHeaderComponent } from '@equans/components';
import { BroadcastService } from '@equans/core';
import { AuthNService, OidcBaseAppComponent } from '@equans/security';
import {
  AccessDeniedDialogComponent,
  AppFacade,
  FooterComponent,
  OrderTypeBannerComponent,
  OrderTypeSelectionDialogComponent,
  OrderTypeSelectorComponent,
  SelectOrderTypeRequest,
} from '@webshop/core/api';
import { ACCESS_DENIED_MESSAGE } from '@webshop/shared';
import { injectDestroy } from 'ngxtension/inject-destroy';
import { injectNavigationEnd } from 'ngxtension/navigation-end';
import { map, takeUntil } from 'rxjs';

@Component({
  standalone: true,
  imports: [
    RouterOutlet,
    AsyncPipe,
    LayoutComponent,
    LayoutFooterComponent,
    FooterComponent,
    AccessDeniedDialogComponent,
    LayoutHeaderComponent,
    OrderTypeSelectorComponent,
    OrderTypeSelectionDialogComponent,
    OrderTypeBannerComponent,
  ],
  selector: 'app-root',
  templateUrl: './app.component.html',
  hostDirectives: [LayoutDirective],
})
export class AppComponent extends OidcBaseAppComponent implements OnInit {
  private readonly destroy$ = injectDestroy();
  private readonly authnService = inject(AuthNService);
  private readonly broadcastService = inject(BroadcastService);
  readonly facade = inject(AppFacade);
  readonly hideLayout = toSignal(
    injectNavigationEnd().pipe(
      map(({ urlAfterRedirects }) => urlAfterRedirects.startsWith('/inventory/document')),
      takeUntil(this.destroy$)
    ),
    { initialValue: false }
  );
  readonly showAccessDeniedPopup = signal(false);

  override ngOnInit(): void {
    super.ngOnInit();
    this.broadcastService
      .messageOfType(ACCESS_DENIED_MESSAGE, true)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.showAccessDeniedPopup.set(true);
      });
    this.facade.initOrderTypeSettings();
    this.facade.loadCurrentOrderType();
  }

  signOutUser(): void {
    this.authnService.logoff();
  }

  repaintOrderTypeSelector(orderTypeSelector: OrderTypeSelectorComponent): void {
    orderTypeSelector.repaintDxButton();
  }

  showOrderTypeSelectionDialog(): void {
    this.facade.showOrderTypeSelectionDialog();
  }

  closeOrderTypeSelectionDialog(): void {
    this.facade.closeOrderTypeSelectionDialog();
  }

  selectOrderType($event: SelectOrderTypeRequest): void {
    this.facade.selectOrderType($event);
  }

  hideOrderTypeBanner(): void {
    this.facade.hideOrderTypeBanner();
  }
}
