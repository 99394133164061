import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, OnInit } from '@angular/core';
import { provideTranslocoScope, TranslocoDirective } from '@jsverse/transloco';
import { WarehouseFacade } from '@webshop/core/domain';
import { DxLoadIndicatorModule } from 'devextreme-angular';

@Component({
  selector: 'core-warehouses',
  standalone: true,
  imports: [TranslocoDirective, AsyncPipe, DxLoadIndicatorModule],
  templateUrl: './warehouses.component.html',
  styleUrl: './warehouses.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [provideTranslocoScope({ scope: 'core/ui-components' })],
})
export class WarehousesComponent implements OnInit {
  readonly facade = inject(WarehouseFacade);

  ngOnInit(): void {
    this.facade.loadAll();
  }
}
