import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { zodParse } from '@equans/core';
import { Observable } from 'rxjs';

import { FullWarehouse, FULL_WAREHOUSE_SCHEMA } from '../entities/full-warehouse';

@Injectable({ providedIn: 'root' })
export class WarehouseService {
  private readonly http = inject(HttpClient);

  loadAllWarehouses(): Observable<FullWarehouse[]> {
    return this.http.get('/api/warehouse').pipe(zodParse(FULL_WAREHOUSE_SCHEMA.array()));
  }
}
