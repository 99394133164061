import { getRouterSelectors } from '@ngrx/router-store';
import { createFeature, createReducer, createSelector, on } from '@ngrx/store';

import { selectAppState, selectWarehousesState } from './core.selectors';
import { initialState } from './core.state';
import { OrderTypeActions } from './order-type/order-type.actions';
import * as OrderTypeReducers from './order-type/order-type.reducer';
import { WarehouseActions } from './warehouse/warehouse.actions';
import * as WarehouseReducers from './warehouse/warehouse.reducer';

export const coreFeature = createFeature({
  name: 'core',
  reducer: createReducer(
    initialState,
    on(OrderTypeActions.loadCurrentSucceeded, OrderTypeReducers.loadCurrentSucceeded),
    on(OrderTypeActions.loadCurrentFailed, OrderTypeReducers.loadCurrentFailed),
    on(OrderTypeActions.showSelectionDialog, OrderTypeReducers.showSelectionDialog),
    on(OrderTypeActions.closeSelectionDialog, OrderTypeReducers.closeSelectionDialog),
    on(OrderTypeActions.availableOrderTypesLoaded, OrderTypeReducers.availableOrderTypesLoaded),
    on(OrderTypeActions.availableOrderTypesLoadingFailed, OrderTypeReducers.availableOrderTypesLoadingFailed),
    on(OrderTypeActions.selectOrderTypeSucceeded, OrderTypeReducers.selectOrderTypeSucceeded),
    on(OrderTypeActions.selectOrderTypeFailed, OrderTypeReducers.selectOrderTypeFailed),
    on(OrderTypeActions.setSettings, OrderTypeReducers.setSettings),
    on(WarehouseActions.loadAll, WarehouseReducers.loadAll),
    on(WarehouseActions.loadAllSucceeded, WarehouseReducers.loadAllSucceeded),
    on(WarehouseActions.loadAllFailed, WarehouseReducers.loadAllFailed),
    on(WarehouseActions.alreadyAllLoaded, WarehouseReducers.alreadyAllLoaded)
  ),
  extraSelectors: ({ selectCoreState }) => {
    const { selectRouteData } = getRouterSelectors();
    const selectForWarehouses = createSelector(selectCoreState, selectWarehousesState);
    const selectForApp = createSelector(selectCoreState, selectRouteData, selectAppState);
    return {
      selectForApp,
      selectForWarehouses,
    };
  },
});
