import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { zodParse } from '@equans/core';
import { Observable } from 'rxjs';

import { SelectOrderTypeRequest } from '../dtos/select-order-type-request';
import { FULL_ORDER_TYPE_SCHEMA, FullOrderType } from '../entities/full-order-type';
import { SIMPLE_ORDER_TYPE_SCHEMA, SimpleOrderType } from '../entities/simple-order-type';

@Injectable({ providedIn: 'root' })
export class OrderTypeService {
  private readonly http = inject(HttpClient);

  loadCurrentOrderType(): Observable<SimpleOrderType> {
    return this.http.get('/api/OrderType/current').pipe(zodParse(SIMPLE_ORDER_TYPE_SCHEMA));
  }

  loadAvailableOrderTypes(): Observable<FullOrderType[]> {
    return this.http.get('/api/OrderType/all-availables').pipe(zodParse(FULL_ORDER_TYPE_SCHEMA.array()));
  }

  selectOrderType(payload: SelectOrderTypeRequest): Observable<unknown> {
    return this.http.put('/api/OrderType/current', payload);
  }
}
