import { Clipboard } from '@angular/cdk/clipboard';
import { formatDate } from '@angular/common';
import { ChangeDetectionStrategy, Component, LOCALE_ID, OnInit, computed, inject, signal } from '@angular/core';
import { DateTimePipe } from '@equans/common';
import { VersionInfo, injectVersionInfo, isNeitherNullNorUndefined, isNullOrUndefined } from '@equans/core';
import { BackendVersionService } from '@webshop/core/domain';
import { getYear } from 'date-fns';
import { DxButtonModule, DxPopoverModule } from 'devextreme-angular';

@Component({
  selector: 'core-footer',
  standalone: true,
  imports: [DxPopoverModule, DateTimePipe, DxButtonModule],
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterComponent implements OnInit {
  private readonly locale = inject(LOCALE_ID);
  private readonly clipboard = inject(Clipboard);
  private readonly backendVersionService = inject(BackendVersionService);
  readonly year = getYear(new Date());
  readonly frontendVersion = signal(injectVersionInfo()).asReadonly();
  readonly backendVersion = signal<VersionInfo | undefined>(undefined);
  readonly buttonLabel = computed(() => {
    const frontendVersion = this.frontendVersion();
    const backendVersion = this.backendVersion();
    const backendSemVer = isNeitherNullNorUndefined(backendVersion) ? ` / ${backendVersion.semVer}` : '';
    return `${frontendVersion.semVer}${backendSemVer}`;
  });

  ngOnInit(): void {
    this.backendVersionService.getVersion().subscribe({
      next: (version) => {
        this.backendVersion.set(version);
      },
    });
  }

  copyVersionInfoToClipboard(): void {
    this.clipboard.copy(this.getVersionInfoCopy());
  }

  private getVersionInfoCopy(): string {
    const frontendVersion = this.frontendVersion();
    const backendVersion = this.backendVersion();
    const backendVersionInfo = isNullOrUndefined(backendVersion)
      ? ''
      : `
## Backend
Version: ${backendVersion.semVer}
Build Date: ${formatDate(backendVersion.date, 'dd/MM/yyyy HH:mm', this.locale)}
Git Hash: ${backendVersion.gitHash}
Full Build Metadata: ${backendVersion.fullBuildMetaData}
`;

    return `
# Logistics Webshop Version Information

## Frontend
Version: ${frontendVersion.semVer}
Build Date: ${formatDate(frontendVersion.date, 'dd/MM/yyyy HH:mm', this.locale)}
Git Hash: ${frontendVersion.gitHash}
Full Build Metadata: ${frontendVersion.fullBuildMetaData}
${backendVersionInfo}
`.trim();
  }
}
