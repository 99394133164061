import { z } from 'zod';

import { SIMPLE_WAREHOUSE_SCHEMA } from './simple-warehouse';

export const FULL_ORDER_TYPE_SCHEMA = z
  .object({
    id: z.number().int().positive(),
    code: z.string(),
    wouldResetShoppingCart: z.boolean(),
    availableInWarehouses: z.array(SIMPLE_WAREHOUSE_SCHEMA).nullable(),
  })
  .strict()
  .describe('Core.Domain.Entities.FullOrderType');

export type FullOrderType = z.infer<typeof FULL_ORDER_TYPE_SCHEMA>;
