<ng-container *transloco="let t; prefix: 'coreUiComponents.components.orderTypeSelectionDialog'">
  <dx-popup
    data-testid="popup"
    height="25vh"
    maxWidth="550px"
    minHeight="425px"
    minWidth="350px"
    width="80vw"
    [title]="t('title')"
    [visible]="visible()"
    [wrapperAttr]="{ class: 'popup-overflow-hidden' }"
  >
    <dxi-toolbar-item
      location="after"
      toolbar="bottom"
      widget="dxButton"
      [options]="{ text: t('ok'), onClick: onSelectOrderType, stylingMode: 'contained' } | disableToolbarButton: disableOkButton()"
    />
    <dxi-toolbar-item
      location="after"
      toolbar="bottom"
      widget="dxButton"
      [options]="{ text: t('cancel'), onClick: onCancel, stylingMode: 'outlined' }"
    />
    <div *dxTemplate="let data of 'content'" class="position-relative">
      @if (loading()) {
        <div class="position-absolute top-50 start-50 translate-middle">
          <dx-load-indicator data-testid="load-indicator" height="40" width="40" />
        </div>
      } @else {
        <dx-accordion
          data-testid="available-order-types"
          keyExpr="id"
          [dataSource]="orderTypes()"
          [selectedItemKeys]="[selectedOrderTypeId()]"
          (selectedItemKeysChange)="setSelectedOrderTypeId($event[0])"
        >
          <div *dxTemplate="let orderType of 'title'">
            {{ t(orderType.code) }}
          </div>
          <div *dxTemplate="let orderType of 'item'">
            @if (orderType.warehouses.length > 1 && orderType.warehouses[0].id !== FULL_CATALOG_ID) {
              <p class="text-center" [class.mb-0]="orderType.wouldResetShoppingCart">{{ t('selectWarehouse') }}</p>
              @if (orderType.wouldResetShoppingCart) {
                <p class="text-center">
                  <strong>{{ t('wouldClearShoppingCart') }}</strong>
                </p>
              }
              <hr />
            }
            @if (orderType.warehouses.length > 0) {
              <dx-radio-group
                displayExpr="name"
                valueExpr="id"
                [items]="orderType.warehouses"
                [value]="selectedWarehouseId()"
                (valueChange)="setSelectedWarehouseId($event)"
              >
                <div *dxTemplate="let warehouse of 'item'">
                  @if (warehouse.id === warehouse.name) {
                    <span>{{ t('fullCatalog') }}</span>
                  } @else {
                    <span>{{ warehouse.name }}</span>
                  }
                </div>
              </dx-radio-group>
            } @else {
              <p class="text-center">{{ t('noWarehouseAvailable') }}</p>
            }
          </div>
        </dx-accordion>
      }
    </div>
  </dx-popup>
</ng-container>
