import { ChangeDetectionStrategy, Component, effect, inject, input, untracked } from '@angular/core';
import { Router } from '@angular/router';
import { isNeitherNullNorUndefined, LoggerService } from '@equans/core';
import { provideTranslocoScope, TranslocoDirective } from '@jsverse/transloco';
import { DxButtonModule } from 'devextreme-angular';

@Component({
  selector: 'core-not-found',
  standalone: true,
  imports: [TranslocoDirective, DxButtonModule],
  templateUrl: './not-found.component.html',
  // eslint-disable-next-line @angular-eslint/component-max-inline-declarations -- should be 5 lines max or disabled
  styles: [
    `
      .fa-stack.fa-2x > .fa-solid.fa-xmark.fa-stack-1x {
        left: -5px;
        top: -4px;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [provideTranslocoScope({ scope: 'core/ui-components' })],
})
export class NotFoundComponent {
  private readonly router = inject(Router);
  private readonly logger = inject(LoggerService);
  readonly source = input<string>();
  readonly cause = input<string>();
  readonly param = input<string>();

  constructor() {
    effect(() => {
      const source = this.source();
      untracked(() => {
        const cause = this.cause();
        const param = this.param();
        if (isNeitherNullNorUndefined(source)) {
          this.logger.info('Reason:', source, cause, param);
        }
      });
    });
  }

  navigateToHomePage(): void {
    void this.router.navigate(['/'], { replaceUrl: true });
  }
}
