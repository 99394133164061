import { ChangeDetectionStrategy, Component, computed, input, output, viewChild } from '@angular/core';
import { TranslocoDirective, provideTranslocoScope } from '@jsverse/transloco';
import { SimpleOrderType } from '@webshop/core/domain';
import { DxButtonComponent, DxButtonModule } from 'devextreme-angular';

@Component({
  selector: 'core-order-type-selector',
  standalone: true,
  imports: [DxButtonModule, TranslocoDirective],
  templateUrl: './order-type-selector.component.html',
  styleUrl: './order-type-selector.component.scss',
  changeDetection: ChangeDetectionStrategy.Default,
  providers: [provideTranslocoScope({ scope: 'core/ui-components' })],
})
export class OrderTypeSelectorComponent {
  readonly orderType = input.required<SimpleOrderType>();
  readonly onClick = output();
  readonly code = computed(() => this.orderType().code);
  readonly warehouse = computed(() => this.orderType().warehouse);
  readonly button = viewChild.required(DxButtonComponent);

  repaintDxButton(): void {
    this.button().instance.repaint();
  }
}
