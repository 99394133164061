import { z } from 'zod';

export const ORDER_TYPE_SETTINGS_SCHEMA = z
  .object({
    showBanner: z.boolean(),
  })
  .strict()
  .describe('Core.Domain.Models.OrderTypeSettings');

export type OrderTypeSettings = z.infer<typeof ORDER_TYPE_SETTINGS_SCHEMA>;
