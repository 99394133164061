import { ChangeDetectionStrategy, Component, computed, input, output } from '@angular/core';
import { provideTranslocoScope, TranslocoDirective } from '@jsverse/transloco';
import { OrderTypeSettings, SimpleOrderType } from '@webshop/core/domain';

const STANDARD_ORDER_TYPE = 'standard';

@Component({
  selector: 'core-order-type-banner',
  standalone: true,
  imports: [TranslocoDirective],
  templateUrl: './order-type-banner.component.html',
  styleUrl: './order-type-banner.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [provideTranslocoScope({ scope: 'core/ui-components' })],
})
export class OrderTypeBannerComponent {
  readonly currentOrderType = input.required<SimpleOrderType>();
  readonly settings = input.required<OrderTypeSettings | undefined>();
  readonly showBanner = computed(() => this.settings()?.showBanner ?? false);
  readonly textKey = computed(() => this.currentOrderType().code);
  readonly mobileTextKey = computed(() => `mobile.${this.currentOrderType().code}`);
  readonly textParams = computed(() => {
    const orderType = this.currentOrderType();
    if (orderType.code === STANDARD_ORDER_TYPE) {
      return {};
    }

    return {
      warehouse: orderType.warehouse?.name ?? 'N/A',
      cutoff: orderType.cutOffTime ?? '00:00',
    };
  });
  readonly hideBanner = output();
}
