@if (!isIframe) {
  @if (hideLayout()) {
    <router-outlet />
  } @else {
    @if (facade.state$ | async; as state) {
      <eq-layout appTitleKey="app.title" [isCompatMode]="true" [showEquansLogo]="true">
        @if (state.currentOrderType && state.showCurrentOrderTypeButton) {
          <eq-layout-header
            cssClass="item-requiring-vertical-spacing"
            locateInMenu="auto"
            location="after"
            (dxRepaintRequired)="repaintOrderTypeSelector(orderTypeSelector)"
          >
            <core-order-type-selector #orderTypeSelector [orderType]="state.currentOrderType" (onClick)="showOrderTypeSelectionDialog()" />
          </eq-layout-header>
          <core-order-type-banner
            [currentOrderType]="state.currentOrderType"
            [settings]="state.orderTypeSettings"
            (hideBanner)="hideOrderTypeBanner()"
          />
          <core-order-type-selection-dialog
            [availableOrderTypes]="state.availableOrderTypes"
            [currentOrderType]="state.currentOrderType"
            [loading]="state.availableOrderTypesLoading"
            [visible]="state.showOrderTypeSelectionDialog"
            (cancel)="closeOrderTypeSelectionDialog()"
            (selectOrderType)="selectOrderType($event)"
          />
        }
        <eq-layout-footer>
          <core-footer />
        </eq-layout-footer>
        <router-outlet />
      </eq-layout>
    }
  }
  <core-access-denied-dialog [(visible)]="showAccessDeniedPopup" (refresh)="signOutUser()" />
}
