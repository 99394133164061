<ng-container *transloco="let t; prefix: 'coreUiComponents.pages.notFound'">
  <h2 class="content-block">{{ t('title') }}</h2>
  <div class="w-100 h-100 d-flex flex-column align-items-center">
    <span class="fa-stack fa-2x">
      <i class="fa-solid fa-xmark fa-stack-1x"></i>
      <i class="fa-solid fa-magnifying-glass fa-stack-2x"></i>
    </span>
    <p class="mt-3 mb-3">{{ t('message') }}</p>
    <dx-button stylingMode="outlined" type="normal" [text]="t('goToHomePage')" (onClick)="navigateToHomePage()" />
  </div>
</ng-container>
