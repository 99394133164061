<ng-container *transloco="let t; prefix: 'coreUiComponents.pages.accessDenied'">
  <h2 class="content-block">{{ t('title') }}</h2>
  <div class="w-100 h-100 d-flex flex-column align-items-center">
    <i class="fa-solid fa-do-not-enter fa-5x text-danger"></i>
    <p class="mt-3 mb-3">
      {{ t('message') }}
      <a
        href="https://it4u.service-now.com/esc?id=sc_cat_item&sys_id=eda4e7c81bd7a85003125605464bcbf3"
        rel="noopener noreferrer"
        target="_blank"
        >Octopus</a
      >
    </p>
    <dx-button stylingMode="outlined" type="normal" [text]="t('goToHomePage')" (onClick)="navigateToHomePage()" />
  </div>
</ng-container>
