import { Injectable } from '@angular/core';
import { injectStore } from '@webshop/shared';

import { coreFeature } from '../+state/core.feature';
import { WarehouseActions } from '../+state/warehouse/warehouse.actions';

@Injectable({ providedIn: 'root' })
export class WarehouseFacade {
  private readonly store = injectStore();
  readonly state$ = this.store.select(coreFeature.selectForWarehouses);

  loadAll(): void {
    this.store.dispatch(WarehouseActions.loadAll({ refresh: false }));
  }

  reloadAll(): void {
    this.store.dispatch(WarehouseActions.loadAll({ refresh: true }));
  }
}
