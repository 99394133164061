import { isDevMode } from '@angular/core';
import { bootstrapEquansApplication } from '@equans/components';

import { AppComponent } from './app/app.component';
import { APP_CONFIG } from './app/app.config';
import { DEVEXTREME_LICENSE_KEY } from './dx-license-key';

async function enableMockServiceWorker(): Promise<void> {
  if (isDevMode()) {
    const { worker, hasMocks } = await import('./mocks/browser');
    if (hasMocks) {
      await worker.start();
    }
  }
}

if (navigator.userAgent.includes('iPhone')) {
  document.querySelector('[name=viewport]')?.setAttribute('content', 'width=device-width, initial-scale=1, maximum-scale=1');
}

enableMockServiceWorker()
  .then(() => bootstrapEquansApplication(AppComponent, APP_CONFIG, { licenseKey: DEVEXTREME_LICENSE_KEY }))
  // eslint-disable-next-line no-console
  .catch(console.error);
