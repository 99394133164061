import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { VersionInfo, zodParse } from '@equans/core';
import { Observable } from 'rxjs';
import { z } from 'zod';

const VERSION_INFO_SCHEMA = z
  .object({
    semVer: z.string(),
    fullBuildMetaData: z.string(),
    gitHash: z.string(),
    date: z.string(),
  })
  .strict()
  .describe('Core.Domain.Entities.VersionInfo');

@Injectable({ providedIn: 'root' })
export class BackendVersionService {
  private readonly http = inject(HttpClient);

  getVersion(): Observable<VersionInfo> {
    return this.http.get('/api/version').pipe(zodParse(VERSION_INFO_SCHEMA));
  }
}
