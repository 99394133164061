import { State } from '../core.state';

import { WarehouseActions } from './warehouse.actions';

export function loadAll(state: State): State {
  return { ...state, warehouseLoading: true };
}

export function loadAllSucceeded(state: State, { warehouses }: ReturnType<typeof WarehouseActions.loadAllSucceeded>): State {
  return { ...state, warehouses, warehouseLoading: false };
}

export function loadAllFailed(state: State): State {
  return { ...state, warehouses: undefined, warehouseLoading: false };
}

export function alreadyAllLoaded(state: State): State {
  return { ...state, warehouseLoading: false };
}
