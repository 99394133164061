import { Route } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { hasPermissionsToMatchRouteGuard } from '@equans/security';
import { NotFoundComponent, WarehousesComponent } from '@webshop/core/api';
import { featureEnabledToMatchRouteGuard } from '@webshop/shared';

import type { InventoryConfiguration } from '@webshop/inventory/api';

import { InventoryConfigurationServiceImport } from './lazy-imports';
import { RedirectComponent } from './redirect.component';

export const APP_ROUTES: Route[] = [
  {
    path: '',
    pathMatch: 'full',
    component: RedirectComponent,
    canActivate: [MsalGuard],
  },
  {
    path: 'not-found',
    component: NotFoundComponent,
    canActivate: [MsalGuard],
    data: { showCurrentOrderType: false },
  },
  {
    path: 'warehouses',
    component: WarehousesComponent,
    canActivate: [MsalGuard],
    data: { showCurrentOrderType: false },
  },
  {
    path: 'inventory',
    loadChildren: () => import('@webshop/inventory/api').then((m) => m.ROUTES),
    canMatch: [
      MsalGuard,
      hasPermissionsToMatchRouteGuard(
        [
          'Permissions.Inventory.Display',
          'Permissions.Inventory.Return.Consumables',
          'Permissions.Inventory.Return.Tools',
          'Permissions.Inventory.Loss',
          'Permissions.Inventory.Theft',
          'Permissions.Inventory.UnrepairableTool',
        ],
        'OR'
      ),
      featureEnabledToMatchRouteGuard(InventoryConfigurationServiceImport, (config: InventoryConfiguration) => config.isEnabled),
    ],
    data: { showCurrentOrderType: false },
  },
  {
    path: 'admin',
    children: [
      {
        path: 'inventory',
        loadChildren: () => import('@webshop/inventory/api').then((m) => m.ADMIN_ROUTES),
        canMatch: [MsalGuard, hasPermissionsToMatchRouteGuard(['Permissions.AdminModule.InventoryManagement'])],
      },
    ],
    canActivateChild: [MsalGuard],
    data: { showCurrentOrderType: false },
  },
  {
    path: 'demo',
    loadComponent: () => import('./demo/demo.component').then((m) => m.DemoComponent),
    canActivate: [MsalGuard],
    data: { showCurrentOrderType: true },
  },
  { path: '**', redirectTo: '' }, // TODO: redirect to /not-found when the entire webshop is migrated to Angular
];
