import { z } from 'zod';

export const SIMPLE_WAREHOUSE_SCHEMA = z
  .object({
    id: z.string().uuid(),
    name: z.string(),
    isSelected: z.boolean(),
  })
  .strict()
  .describe('Core.Domain.Entities.SimpleWarehouse');
