import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { SelectOrderTypeRequest } from '../../dtos/select-order-type-request';
import { FullOrderType } from '../../entities/full-order-type';
import { SimpleOrderType } from '../../entities/simple-order-type';
import { OrderTypeSettings } from '../../models/order-type-settings';

export const OrderTypeActions = createActionGroup({
  source: 'OrderType',
  events: {
    'Load Current': emptyProps(),
    'Load Current Succeeded': props<{ currentOrderType: SimpleOrderType }>(),
    'Load Current Failed': emptyProps(),
    'Show Selection Dialog': emptyProps(),
    'Close Selection Dialog': emptyProps(),
    'Available Order Types Loaded': props<{ availableOrderTypes: FullOrderType[] }>(),
    'Available Order Types Loading Failed': emptyProps(),
    'Select Order Type': props<{ orderType: SelectOrderTypeRequest }>(),
    'Select Order Type Succeeded': emptyProps(),
    'Select Order Type Failed': emptyProps(),
    'Notify Current Order Type Changed': emptyProps(),
    'Init Settings': emptyProps(),
    'Set Settings': props<{ settings: OrderTypeSettings }>(),
  },
});
