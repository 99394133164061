import { EnvironmentProviders, makeEnvironmentProviders } from '@angular/core';
import { provideTranslocoScope } from '@jsverse/transloco';
import { provideEffects } from '@ngrx/effects';
import { provideState } from '@ngrx/store';

import { coreFeature } from './+state/core.feature';
import {
  currentOrderTypeChanged$,
  initOrderTypeSettings$,
  loadCurrentOrderType$,
  notifyCurrentOrderTypeChanged$,
  selectOrderType$,
  setOrderTypeSettings$,
  showOrderTypeSelectionDialog$,
} from './+state/order-type/order-type.effects';
import { loadAllWarehouses$ } from './+state/warehouse/warehouse.effects';

export function provideCoreDomain(): EnvironmentProviders {
  return makeEnvironmentProviders([
    provideState(coreFeature),
    provideEffects({
      initOrderTypeSettings$,
      setSettings$: setOrderTypeSettings$,
      loadCurrentOrderType$,
      selectOrderType$,
      notifyCurrentOrderTypeChanged$,
      currentOrderTypeChanged$,
      showOrderTypeSelectionDialog$,
      loadAllWarehouses$,
    }),
    provideTranslocoScope({ scope: 'core/domain' }),
  ]);
}
