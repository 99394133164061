<div class="footer-content">
  <span>&copy; {{ year }} - EQUANS Logistics</span>
  <dx-button
    class="text-normal"
    id="app-version"
    stylingMode="text"
    [focusStateEnabled]="false"
    [hoverStateEnabled]="false"
    [text]="buttonLabel()"
  />
</div>
<dx-popover maxWidth="97vw" showEvent="click" target="#app-version">
  <dxo-animation>
    <dxo-show type="pop" [from]="{ scale: 0 }" [to]="{ scale: 1 }" />
    <dxo-hide type="fade" [from]="1" [to]="0" />
  </dxo-animation>
  <dxo-position at="right top" my="right bottom" of="#app-version" />
  <div *dxTemplate="let data of 'content'" class="position-relative auto-show-copy-button">
    <h5>Frontend</h5>
    <div class="row">
      <div class="col-3 text-start fw-bold">Version:</div>
      <div class="col-9 text-end text-truncate font-monospace">{{ frontendVersion().semVer }}</div>
      <div class="col-3 text-start fw-bold">Build Date:</div>
      <div class="col-9 text-end text-truncate font-monospace">{{ frontendVersion().date | eqDateTime }}</div>
      <div class="col-3 text-start fw-bold">Git Hash:</div>
      <div class="col-9 text-end text-truncate font-monospace">{{ frontendVersion().gitHash }}</div>
      <div class="col-3 text-start fw-bold">Full Build Metadata:</div>
      <div class="col-9 text-end text-truncate font-monospace">{{ frontendVersion().fullBuildMetaData }}</div>
    </div>
    @if (backendVersion(); as backendVersion) {
      <hr />
      <h5 class="mt-3">Backend</h5>
      <div class="row">
        <div class="col-3 text-start fw-bold">Version:</div>
        <div class="col-9 text-end text-truncate font-monospace">{{ backendVersion.semVer }}</div>
        <div class="col-3 text-start fw-bold">Build Date:</div>
        <div class="col-9 text-end text-truncate font-monospace">{{ backendVersion.date | eqDateTime }}</div>
        <div class="col-3 text-start fw-bold">Git Hash:</div>
        <div class="col-9 text-end text-truncate font-monospace">{{ backendVersion.gitHash }}</div>
        <div class="col-3 text-start fw-bold">Full Build Metadata:</div>
        <div class="col-9 text-end text-truncate font-monospace">{{ backendVersion.fullBuildMetaData }}</div>
      </div>
    }
    <dx-button
      class="position-absolute top-0 end-0 dx-shape-standard"
      hint="Copy to clipboard"
      icon="fa-light fa-copy"
      stylingMode="outlined"
      (onClick)="copyVersionInfoToClipboard()"
    />
  </div>
</dx-popover>
