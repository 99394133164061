import { State } from '../core.state';

import { OrderTypeActions } from './order-type.actions';

export function loadCurrentSucceeded(state: State, { currentOrderType }: ReturnType<typeof OrderTypeActions.loadCurrentSucceeded>): State {
  return { ...state, currentOrderType };
}

export function loadCurrentFailed(state: State): State {
  return { ...state, currentOrderType: undefined };
}

export function showSelectionDialog(state: State): State {
  return { ...state, showOrderTypeSelectionDialog: true, availableOrderTypes: [], availableOrderTypesLoading: true };
}

export function closeSelectionDialog(state: State): State {
  return { ...state, showOrderTypeSelectionDialog: false, availableOrderTypes: [], availableOrderTypesLoading: false };
}

export function availableOrderTypesLoaded(
  state: State,
  { availableOrderTypes }: ReturnType<typeof OrderTypeActions.availableOrderTypesLoaded>
): State {
  return { ...state, availableOrderTypes, availableOrderTypesLoading: false };
}

export function availableOrderTypesLoadingFailed(state: State): State {
  return { ...state, availableOrderTypes: [], availableOrderTypesLoading: false };
}

export function selectOrderTypeSucceeded(state: State): State {
  return { ...state, showOrderTypeSelectionDialog: false, availableOrderTypes: [] };
}

export function selectOrderTypeFailed(state: State): State {
  return { ...state, showOrderTypeSelectionDialog: false, availableOrderTypes: [] };
}

export function setSettings(state: State, { settings }: ReturnType<typeof OrderTypeActions.setSettings>): State {
  return { ...state, orderTypeSettings: settings };
}
