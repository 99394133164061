import { computed } from '@angular/core';
import { isNeitherNullNorUndefined, isNullOrUndefined } from '@equans/core';
import { patchState, signalStore, withComputed, withMethods, withState } from '@ngrx/signals';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { FullOrderType, SimpleOrderType } from '@webshop/core/domain';
import { pipe, tap } from 'rxjs';

interface OrderTypeSelectionDialogState {
  availableOrderTypes: FullOrderType[];
  currentOrderType: SimpleOrderType;
  selectedOrderTypeId: number | undefined;
  selectedWarehouseId: string | undefined;
  warehouseSelectionPerOrderType: Record<number, string | undefined>;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export const FULL_CATALOG_ID = 'full-catalog';

export const OrderTypeSelectionDialogStore = signalStore(
  withState<OrderTypeSelectionDialogState>({
    availableOrderTypes: [],
    // eslint-disable-next-line unicorn/no-null -- null is fine when working with DTOs
    currentOrderType: { id: 0, code: '', warehouse: null, cutOffTime: null },
    selectedOrderTypeId: undefined,
    selectedWarehouseId: undefined,
    warehouseSelectionPerOrderType: {},
  }),
  withComputed(({ availableOrderTypes, selectedOrderTypeId, selectedWarehouseId }) => ({
    orderTypes: computed(() => {
      return availableOrderTypes().map((ot) => ({
        id: ot.id,
        code: ot.code,
        warehouses: ot.availableInWarehouses?.map((w) => ({ id: `${ot.id}#${w.id}`, name: w.name })) ?? [
          { id: FULL_CATALOG_ID, name: FULL_CATALOG_ID },
        ],
        wouldResetShoppingCart: ot.wouldResetShoppingCart,
      }));
    }),
    disableOkButton: computed(() => {
      return isNullOrUndefined(selectedOrderTypeId()) || isNullOrUndefined(selectedWarehouseId());
    }),
    selectedOrderTypeAndWarehouse: computed(() => {
      const orderTypeId = selectedOrderTypeId();
      if (isNeitherNullNorUndefined(orderTypeId)) {
        let warehouseId = selectedWarehouseId();
        if (isNeitherNullNorUndefined(warehouseId)) {
          warehouseId = warehouseId === FULL_CATALOG_ID ? undefined : warehouseId.split('#')[1];
        }

        return { orderTypeId, warehouseId };
      }

      return undefined;
    }),
  })),
  withMethods((store) => ({
    init: rxMethod<{ availableOrderTypes: FullOrderType[]; currentOrderType: SimpleOrderType }>(
      pipe(
        tap(({ availableOrderTypes, currentOrderType }) => {
          const warehouseSelectionPerOrderType = Object.fromEntries(
            availableOrderTypes.map((ot) => {
              const warehouseId = ot.id === currentOrderType.id ? `${ot.id}#${currentOrderType.warehouse?.id}` : undefined;
              return [ot.id, isNeitherNullNorUndefined(ot.availableInWarehouses) ? warehouseId : FULL_CATALOG_ID];
            })
          );
          const selectedOrderTypeId = currentOrderType.id;
          const selectedWarehouseId = isNeitherNullNorUndefined(currentOrderType.warehouse)
            ? `${currentOrderType.id}#${currentOrderType.warehouse.id}`
            : FULL_CATALOG_ID;
          patchState(store, {
            availableOrderTypes,
            currentOrderType,
            warehouseSelectionPerOrderType,
            selectedOrderTypeId,
            selectedWarehouseId,
          });
        })
      )
    ),
    setSelectedOrderTypeId(selectedOrderTypeId: number) {
      const previouslySelectedWarehouseId = store
        .availableOrderTypes()
        .find((x) => x.id === selectedOrderTypeId)
        ?.availableInWarehouses?.find((y) => y.isSelected)?.id;
      const selectedWarehouseId =
        store.warehouseSelectionPerOrderType()[selectedOrderTypeId] ??
        (isNeitherNullNorUndefined(previouslySelectedWarehouseId) ? `${selectedOrderTypeId}#${previouslySelectedWarehouseId}` : undefined);
      patchState(store, { selectedOrderTypeId, selectedWarehouseId });
    },
    setSelectedWarehouseId(selectedWarehouseId: string) {
      patchState(store, { selectedWarehouseId });
      const selectedOrderTypeId = store.selectedOrderTypeId();
      if (isNeitherNullNorUndefined(selectedOrderTypeId)) {
        patchState(store, {
          warehouseSelectionPerOrderType: { ...store.warehouseSelectionPerOrderType(), [selectedOrderTypeId]: selectedWarehouseId },
        });
      }
    },
  }))
);
