import { ChangeDetectionStrategy, Component, model, output } from '@angular/core';
import { provideTranslocoScope, TranslocoDirective } from '@jsverse/transloco';
import { DxPopupModule } from 'devextreme-angular';

@Component({
  selector: 'core-access-denied-dialog',
  standalone: true,
  imports: [TranslocoDirective, DxPopupModule],
  templateUrl: './access-denied-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [provideTranslocoScope({ scope: 'core/ui-components' })],
})
export class AccessDeniedDialogComponent {
  readonly visible = model.required<boolean>();
  readonly refresh = output();

  constructor() {
    this.onRefresh = this.onRefresh.bind(this);
    this.onClose = this.onClose.bind(this);
  }

  onRefresh(): void {
    this.refresh.emit();
  }

  onClose(): void {
    this.visible.set(false);
  }
}
