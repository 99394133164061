import { inject } from '@angular/core';
import { BroadcastService, isNullOrUndefined, LocalStorageService, LoggerService } from '@equans/core';
import { createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { handleError, injectActions, injectStore } from '@webshop/shared';
import { catchError, concatMap, filter, from, map, switchMap, takeUntil, tap } from 'rxjs';

import { OrderTypeService } from '../../infrastructure/order-type.service';
import { ORDER_TYPE_SETTINGS_SCHEMA } from '../../models/order-type-settings';
import { coreFeature } from '../core.feature';

import { OrderTypeActions } from './order-type.actions';

export const initOrderTypeSettings$ = createEffect(
  (actions$ = injectActions(), localStorage = inject(LocalStorageService), loggerService = inject(LoggerService)) => {
    return actions$.pipe(
      ofType(OrderTypeActions.initSettings),
      map(() => {
        const settings = localStorage.safeGet('orderTypeSettings', ORDER_TYPE_SETTINGS_SCHEMA, { showBanner: true }, true);
        return OrderTypeActions.setSettings({ settings });
      }),
      catchError((error) => {
        loggerService.debug('[ERROR] Failed to load order type settings. Falling back to default value.', error);
        return from([OrderTypeActions.setSettings({ settings: { showBanner: true } })]);
      })
    );
  },
  { functional: true }
);

export const setOrderTypeSettings$ = createEffect(
  (actions$ = injectActions(), localStorage = inject(LocalStorageService)) => {
    return actions$.pipe(
      ofType(OrderTypeActions.setSettings),
      tap(({ settings }) => localStorage.set('orderTypeSettings', settings))
    );
  },
  { functional: true, dispatch: false }
);

export const loadCurrentOrderType$ = createEffect(
  (actions$ = injectActions(), orderTypeService = inject(OrderTypeService)) => {
    return actions$.pipe(
      ofType(OrderTypeActions.loadCurrent),
      switchMap(() =>
        orderTypeService.loadCurrentOrderType().pipe(
          map((currentOrderType) => OrderTypeActions.loadCurrentSucceeded({ currentOrderType })),
          handleError('coreDomain.messages.loadCurrentOrderTypeFailed', OrderTypeActions.loadCurrentFailed())
        )
      )
    );
  },
  { functional: true }
);

export const showOrderTypeSelectionDialog$ = createEffect(
  (actions$ = injectActions(), orderTypeService = inject(OrderTypeService)) => {
    return actions$.pipe(
      ofType(OrderTypeActions.showSelectionDialog),
      switchMap(() =>
        orderTypeService.loadAvailableOrderTypes().pipe(
          takeUntil(actions$.pipe(ofType(OrderTypeActions.closeSelectionDialog))),
          map((availableOrderTypes) => OrderTypeActions.availableOrderTypesLoaded({ availableOrderTypes })),
          handleError('coreDomain.messages.loadAvailableOrderTypesFailed', OrderTypeActions.availableOrderTypesLoadingFailed())
        )
      )
    );
  },
  { functional: true }
);

export const selectOrderType$ = createEffect(
  (actions$ = injectActions(), store = injectStore(), orderTypeService = inject(OrderTypeService)) => {
    return actions$.pipe(
      ofType(OrderTypeActions.selectOrderType),
      concatLatestFrom(() => store.select(coreFeature.selectCurrentOrderType)),
      filter(
        ([{ orderType }, currentOrderType]) =>
          isNullOrUndefined(currentOrderType) ||
          currentOrderType.id !== orderType.orderTypeId ||
          currentOrderType.warehouse?.id !== orderType.warehouseId
      ),
      concatMap(([{ orderType }]) =>
        orderTypeService.selectOrderType(orderType).pipe(
          switchMap(() =>
            from([
              OrderTypeActions.setSettings({ settings: { showBanner: true } }),
              OrderTypeActions.selectOrderTypeSucceeded(),
              OrderTypeActions.notifyCurrentOrderTypeChanged(),
            ])
          ),
          handleError('coreDomain.messages.selectOrderTypeFailed', OrderTypeActions.selectOrderTypeFailed())
        )
      )
    );
  },
  { functional: true }
);

// eslint-disable-next-line @typescript-eslint/naming-convention -- not an effect
export const CURRENT_ORDER_TYPE_CHANGED = 'currentOrderTypeChanged';

export const notifyCurrentOrderTypeChanged$ = createEffect(
  (actions$ = injectActions(), broadcastService = inject(BroadcastService)) => {
    return actions$.pipe(
      ofType(OrderTypeActions.notifyCurrentOrderTypeChanged),
      tap(() => broadcastService.publish({ type: CURRENT_ORDER_TYPE_CHANGED })),
      map(() => OrderTypeActions.loadCurrent())
    );
  },
  { functional: true }
);

export const currentOrderTypeChanged$ = createEffect(
  (broadcastService = inject(BroadcastService)) => {
    return broadcastService.messageOfType(CURRENT_ORDER_TYPE_CHANGED).pipe(map(() => OrderTypeActions.loadCurrent()));
  },
  { functional: true }
);
